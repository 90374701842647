import Vue from "vue";
import VueRouter from "vue-router";
import VueAnalytics from "vue-ua";
import Home from "../views/Home.vue";
import PageList from "../views/PageList.vue";
import ThePage from "../views/ThePage.vue";
import TheDiagnostic from "../views/TheDiagnostic.vue";
import TheAlgorithm from "../views/TheAlgorithm.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/:slug(diagnostico)/algoritmo",
    name: "TheAlgorithm",
    component: TheAlgorithm
  },
  {
    path: "/:slug(diagnostico)",
    name: "TheDiagnostic",
    props: route => ({ slug: route.path }),
    component: TheDiagnostic
  },
  {
    path: "/:slug/",
    name: "PageList",
    component: PageList
  },
  {
    path: "/:slug/:subSlug",
    name: "ThePage",
    component: ThePage,
    children: [
      {
        path: ":tabSlug",
        name: "Tabs",
        props: true,
        component: () =>
          import(/* webpackChunkName: "TheTabs" */ "../views/TheTabs")
      }
    ]
  }
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    // console.log(to, from, savedPosition);
    if (savedPosition) {
      //this is for when using the back button so the user doesnt go to the top
      // return savedPosition;
      //cant simply be returned because of the ajax loading
      return new Promise(resolve => {
        setTimeout(() => {
          savedPosition.behavior = "smooth";
          resolve(savedPosition);
        }, 250);
      });
    } else {
      const position = {};
      if (to.hash && !to.params.fromJS) {
        position.selector = to.hash;
        position.behavior = "smooth";
        position.offset = { y: 10 };
        return position;
      } else if (!to.hash) {
        //normal behavior
        return { x: 0, y: 0, behavior: "smooth" };
      }
    }
  },
  routes
});

router.beforeEach((to, from, next) => {
  // console.log('from', from);
  if (from.params.slug) router.app.$root.$data.prevRoute = from.params.slug;
  if (from.name && !from.params.slug) router.app.$root.$data.prevRoute = null;
  // console.log(router.app);
  next();
});

Vue.use(VueAnalytics, {
  // [Required] The name of your app as specified in Google Analytics.
  appName: "DiagnosticarSII ua",
  // [Required] The version of your app.
  appVersion: process.env.VUE_APP_VERSION,
  // [Required] Your Google Analytics tracking ID.
  trackingId: "UA-200020820-1",
  // If you're using vue-router, pass the router instance here.
  vueRouter: router,
  debug: false,
  trackPage: true
});

export default router;
