<template>
  <footer>
    <div class="logo">
      <img src="@/assets/logos/logo-biocodex.svg" alt="Biocodex Logo" />
    </div>
    <div>
      <p>
        <a
          href="https://microbiotaforpeds.pt/assets/pdf/politica_de_protecao_de_dados_PT.pdf"
          target="_blank"
          title="Política de Privacidade"
          rel="noopener"
          >Política de privacidade</a
        >
        |
        <a
          href="https://microbiotaforpeds.pt/assets/pdf/termos_de_utilizacao_PT.pdf"
          target="_blank"
          title="Termos e Condições"
          rel="noopener"
          >Termos e condições</a
        >
        <br />
        X.0063/2021
      </p>
      <p>Diagnosticar a SII ©2021. Todos os direitos reservados</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",

  props: {
    previousPage: {
      type: Object,
      default: null
    },
    nextPage: {
      type: Object,
      default: null
    }
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
footer {
  padding: 5em 0 calc(10px + env(safe-area-inset-bottom));
  text-align: center;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    max-width: 75%;
    margin-right: -5em;
  }

  @media only screen and (min-width: $sm) {
    & {
      text-align: right;
      flex-direction: row;
    }

    .logo {
      text-align: left;
    }

    img {
      margin-right: 0;
    }
  }

  p {
    margin-bottom: 0.4em;
  }
  a {
    color: var(--gray);
  }
}
</style>
