<template>
  <div :class="['page-list', 'page', page.color]">
    <header>
      <h2 class="title">{{ page.name }}</h2>
    </header>

    <div class="featured-image">
      <picture class="featured no-round">
        <img
          :src="require(`@/assets/img/${page.image}`)"
          :alt="page.name"
          v-if="page.image != ''"
        />
      </picture>
    </div>

    <h4>Ferramenta de apoio ao diagnóstico de SII</h4>
    <p>
      Nesta ferramenta interativa, poderá seguir o algoritmo desde a suspeita de
      um eventual caso de Síndrome do Intestino Irritável, até ao diagnóstico
      provável de SII.
    </p>

    <div class="btns-2cols-md">
      <div style="">
        <router-link :to="{ name: 'TheAlgorithm', params: {} }" class="button">
          Iniciar Algoritmo
        </router-link>
      </div>
      <div style="">
        <router-link
          :to="{
            name: 'ThePage',
            params: { slug: 'diagnostico', subSlug: 'algoritmo-completo' }
          }"
          class="button outline"
          >Ver Algoritmo Completo</router-link
        >
      </div>
    </div>

    <h4 class="text-center">Documentação de Apoio</h4>

    <router-link
      :to="{
        name: 'Tabs',
        params: {
          slug: 'diagnostico',
          subSlug: 'escala-bristol-criterios-roma',
          tabSlug: 'bristol'
        }
      }"
      class="button outline"
    >
      Escala de Bristol &amp; Critérios de ROMA
    </router-link>

    <router-link
      :to="{
        name: 'ThePage',
        params: { slug: 'diagnostico', subSlug: 'meios-auxiliares-diagnostico' }
      }"
      class="button outline"
    >
      Meios Auxiliares de Diagnóstico
    </router-link>

    <a
      href="https://www.worldgastroenterology.org/guidelines/global-guidelines/irritable-bowel-syndrome-ibs/irritable-bowel-syndrome-ibs-portuguese"
      title="Guidelines Internacionais"
      target="_blank"
      class="button outline"
    >
      Guidelines Internacionais WGO
      <!-- <img src="@/assets/open-external-link.svg" alt="external site" color="#FFF" width="16px" height="16px" /> -->
    </a>
  </div>
</template>

<script>
export default {
  name: "PageList",
  props: {
    slug: {
      default: false
    }
  },
  data() {
    return {
      pages: this.$store.pages
    };
  },
  components: {
    // HelloWorld
    // HomeSvg
  },
  computed: {
    page() {
      return this.pages.find(page => page.slug === this.$route.params.slug);
    }
  }
};
</script>

<style lang="scss" scoped>
.btns-2cols-md {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: 100%;
    a {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: $md) {
    & > * {
      width: 45%;
      margin: 0 2.5%;
    }
  }
}
</style>
