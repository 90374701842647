<template>
  <div :class="['page', parentPage.color]">
    <header>
      <PrevNextNav :previousPage="previousPage" :nextPage="nextPage" />
      <h2 class="title">{{ parentPage.name }}</h2>
      <div class="featured-image" v-if="childPage.image">
        <picture class="featured">
          <img
            :src="require(`@/assets/img/${childPage.image}`)"
            :alt="childPage.name"
            v-if="childPage.image != ''"
          />
        </picture>
      </div>
    </header>

    <h3
      v-html="childPage.title"
      :class="
        childPage.titleAlignment ? `text-${childPage.titleAlignment}` : ''
      "
    ></h3>
    <div v-html="childPage.html"></div>

    <div v-if="childPage.tabs" id="tabs">
      <div v-for="tab in childPage.tabs" :key="tab.slug">
        <router-link
          :to="{
            name: 'Tabs',
            params: { tabSlug: tab.slug },
            hash: '#tabs'
          }"
          :class="['button']"
          v-html="tab.title"
          exact-path
        >
        </router-link>
      </div>

      <transition name="slide-back">
        <router-view :key="$route.params.tabSlug" />
      </transition>
    </div>

    <!-- share btn -->
    <div class="share mt-4">
      <button class="outline icon sm" @click="sharePage">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M6 17c2.269-9.881 11-11.667 11-11.667v-3.333l7 6.637-7 6.696v-3.333s-6.17-.171-11 5zm12 .145v2.855h-16v-12h6.598c.768-.787 1.561-1.449 2.339-2h-10.937v16h20v-6.769l-2 1.914z"
            fill="var(--pink)"
          />
        </svg>

        Partilhar esta página
      </button>
    </div>

    <div class="refs" v-if="childPage.refs">
      <hr />
      <p>Referências:</p>
      <ol>
        <li v-for="(ref, key) in childPage.refs" :key="key">{{ ref }}</li>
      </ol>
    </div>

    <PrevNextNav :previousPage="previousPage" :nextPage="nextPage" />
  </div>
</template>

<script>
import PrevNextNav from "@/components/PrevNextNav";

export default {
  name: "PageList",
  data() {
    return {
      pages: this.$store.pages
    };
  },
  components: {
    PrevNextNav
  },
  computed: {
    parentPage() {
      // console.log(this.$route.params.slug[0]);
      return this.pages.find(page => page.slug === this.$route.params.slug);
    },
    childPage() {
      return this.parentPage.subPages.find(
        page => page.slug === this.$route.params.subSlug
      );
    },
    childPageIndex() {
      let i = this.parentPage.subPages.findIndex(
        page => page.slug === this.$route.params.subSlug
      );
      return i;
    },
    previousPage() {
      if (this.childPageIndex > 0)
        return this.parentPage.subPages[this.childPageIndex - 1];
      else return null;
    },
    nextPage() {
      if (this.childPageIndex < this.parentPage.subPages.length)
        return this.parentPage.subPages[this.childPageIndex + 1];
      else return null;
    },
    shareData() {
      return {
        title:
          "Alflorex - " + this.parentPage.name + " - " + this.childPage.title,
        text: "Saiba mais sobre SII",
        url: "https://diagnosticarsii.pt/#" + this.$route.fullPath
      };
    }
  },
  methods: {
    sharePage() {
      if (navigator.canShare && navigator.canShare(this.shareData)) {
        navigator
          .share(this.shareData)
          .then(() => console.log("Share was successful."))
          .catch(error => console.log("Sharing failed", error));
      } else {
        alert(
          `O seu navegador não suporta a partilha de páginas web. Em alternativa copie o link da barra de endereço!`
        );
      }
    }
  }
};
</script>

<style lang="scss">
.page {
  list-style-type: none;
  margin: 0;
  padding: 0;

  .featured-image {
    max-width: 15em;
    margin: 0.5em auto;
  }
}
</style>
