import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import store from "@/store.js";
Vue.prototype.$store = store;

// this component will dynamically compile the html
// and use it as template for this component.

Vue.config.productionTip = false;

new Vue({
  router,
  data() {
    return {
      prevRoute: null
    };
  },
  render: h => h(App)
}).$mount("#app");
