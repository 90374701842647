<template>
  <div :class="['page-list', page.color]">
    <header>
      <h2 class="title">{{ page.name }}</h2>
    </header>

    <ul class="sub-menu">
      <li v-for="subPage in page.subPages" :key="subPage.slug">
        <router-link
          :to="{
            name: subPage.type,
            params: { slug: $route.params.slug, subSlug: subPage.slug }
          }"
        >
          <picture class="featured">
            <img
              :src="require(`@/assets/img/${subPage.image}`)"
              :alt="subPage.name"
              v-if="subPage.image != ''"
            />
          </picture>
          <label>
            {{ subPage.name }}
          </label>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PageList",
  data() {
    return {
      pages: this.$store.pages
    };
  },
  components: {
    // HelloWorld
    // HomeSvg
  },
  computed: {
    page() {
      console.log();
      return this.pages.find(page => page.slug === this.$route.params.slug);
    }
  }
};
</script>

<style lang="scss">
.sub-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    padding: 1em;
    width: 15em;

    a {
      text-decoration: none;
      color: var(--gray-dark);
    }

    label {
      display: block;
      text-align: center;
      border: 2px solid var(--gray-dark);
      border-radius: 2.5em;
      padding: 0.5em 0.5em;
      margin: 0.5em 0;
      font-size: 0.8em;
      cursor: pointer;
      font-weight: 600;
      font-size: 1.25em;
    }
  }
}
</style>
