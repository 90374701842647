var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['page-list', 'page', _vm.page.color]},[_c('header',[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.page.name))])]),_c('div',{staticClass:"featured-image"},[_c('picture',{staticClass:"featured no-round"},[(_vm.page.image != '')?_c('img',{attrs:{"src":require(("@/assets/img/" + (_vm.page.image))),"alt":_vm.page.name}}):_vm._e()])]),_c('h4',[_vm._v("Ferramenta de apoio ao diagnóstico de SII")]),_c('p',[_vm._v(" Nesta ferramenta interativa, poderá seguir o algoritmo desde a suspeita de um eventual caso de Síndrome do Intestino Irritável, até ao diagnóstico provável de SII. ")]),_c('div',{staticClass:"btns-2cols-md"},[_c('div',{},[_c('router-link',{staticClass:"button",attrs:{"to":{ name: 'TheAlgorithm', params: {} }}},[_vm._v(" Iniciar Algoritmo ")])],1),_c('div',{},[_c('router-link',{staticClass:"button outline",attrs:{"to":{
          name: 'ThePage',
          params: { slug: 'diagnostico', subSlug: 'algoritmo-completo' }
        }}},[_vm._v("Ver Algoritmo Completo")])],1)]),_c('h4',{staticClass:"text-center"},[_vm._v("Documentação de Apoio")]),_c('router-link',{staticClass:"button outline",attrs:{"to":{
      name: 'Tabs',
      params: {
        slug: 'diagnostico',
        subSlug: 'escala-bristol-criterios-roma',
        tabSlug: 'bristol'
      }
    }}},[_vm._v(" Escala de Bristol & Critérios de ROMA ")]),_c('router-link',{staticClass:"button outline",attrs:{"to":{
      name: 'ThePage',
      params: { slug: 'diagnostico', subSlug: 'meios-auxiliares-diagnostico' }
    }}},[_vm._v(" Meios Auxiliares de Diagnóstico ")]),_c('a',{staticClass:"button outline",attrs:{"href":"https://www.worldgastroenterology.org/guidelines/global-guidelines/irritable-bowel-syndrome-ibs/irritable-bowel-syndrome-ibs-portuguese","title":"Guidelines Internacionais","target":"_blank"}},[_vm._v(" Guidelines Internacionais WGO ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }