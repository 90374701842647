<template>
  <div
    id="app"
    :class="[
      $route.params.slug,
      $route.name,
      'prev-' + prevRoute,
      { transitioning: isTrasitioning }
    ]"
  >
    <div id="top-bar">
      <!--  @click="$router.push('/')" -->
      <div class="logo">
        <div class="no-select">Diagnosticar a</div>
        <router-link :to="{ name: 'Home' }">
          <img
            class="logo"
            src="@/assets/logos/logo-sii.svg"
            alt="SII - Síndrome da Irritabilidade do Intestino"
          />
        </router-link>
      </div>
      <TheNavigation />
    </div>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <div class="content">
      <transition
        name="fade"
        mode="out-in"
        v-on:before-leave="beforeLeave"
        v-on:after-enter="afterEnter"
      >
        <router-view :key="$route.path" />
      </transition>
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheNavigation from "@/components/TheNavigation";
import TheFooter from "@/components/TheFooter";

export default {
  name: "Home",
  components: {
    TheNavigation,
    TheFooter
  },
  data() {
    return {
      isTrasitioning: false,
      prevRoute: null
    };
  },
  methods: {
    beforeLeave: function() {
      this.isTrasitioning = true;
    },
    afterEnter: function() {
      this.isTrasitioning = false;
    }
  },
  computed: {},
  watch: {
    "$root.$data.prevRoute": function(val) {
      //second param of the watch functions is the oldVal
      // console.log("new: %s, old: %s", val, oldVal);
      // console.log(val);
      this.prevRoute = val;
    }
  }
};
</script>
<style lang="scss">
@import "./css/main";
</style>
