var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',[_c('ul',{staticClass:"bottom-menu",attrs:{"role":"navigation"}},_vm._l((_vm.mainMenu),function(item){return _c('li',{key:item.id},[_c('router-link',{class:[
          'icon-' + item.icon,
          'theme-' + item.color,
          'menu-link',
          'no-select'
        ],attrs:{"to":{
          name: item.type,
          params: { slug: item.slug }
        },"title":item.title}},[_c('span'),_c('label',[_vm._v(_vm._s(item.name))])])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }