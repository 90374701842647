<template>
  <div class="prev-next-nav">
    <div class="prev">
      <router-link
        :to="{
          name: previousPage.type,
          params: { slug: $route.params.slug, subSlug: previousPage.slug }
        }"
        v-if="previousPage"
        class="icon-arrow-left"
      >
        {{ previousPage.name }}
      </router-link>
    </div>

    <div class="next">
      <router-link
        :to="{
          name: nextPage.type,
          params: { slug: $route.params.slug, subSlug: nextPage.slug }
        }"
        v-if="nextPage"
        class="icon-arrow-right-after"
      >
        {{ nextPage.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrevNextNav",

  props: {
    previousPage: {
      type: Object,
      default: null
    },
    nextPage: {
      type: Object,
      default: null
    }
  },

  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.prev-next-nav {
  display: flex;
  justify-content: space-between;

  .icon-arrow-right-after:after {
    content: "\e903";
  }

  a {
    text-decoration: none;

    &:after,
    &:before {
      font-size: 0.9em;
    }
  }
  .next {
    text-align: right;
  }
}
</style>
