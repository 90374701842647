export default {
  pages: [
    {
      type: "PageList",
      name: "A Síndrome",
      slug: "a-sindrome",
      icon: "sindrome",
      color: "lime",
      subPages: [
        {
          type: "ThePage",
          name: "O que é?",
          title: "O que é?",
          slug: "o-que-e",
          image: "sindrome/sindrome_o_que_e.jpg",
          html: `
								<p><span class="pink-hl">A síndrome do intestino irritável (SII)</span> é um <span class="pink-hl">distúrbio gastrointestinal funcional</span> definido por critérios de diagnóstico baseados em sintomas na ausência de causas orgânicas detetáveis.</p>

								<p><b>De um modo geral, é uma síndrome caracterizada por:</b></p>

								<section class="gallery-icons">
									<div>
										<img src="img/content/icons/sindrome_img_dor.svg" alt="dor" />
										<span>Dor abdominal</span>
									</div>
									<div>
										<img src="img/content/icons/sindrome_img_desconforto.svg" alt="desconforto" />
										<span>Desconforto abdominal</span>
									</div>
									<div>
										<img src="img/content/icons/sindrome_img_alteracoes.svg" alt="alterações" />
										<span>Alterações a nível de trânsito intestinal</span>
									</div>
								</section>

								<blockquote>
									Distensão e mudança na frequência e forma dos hábitos intestinais são também características geralmente associadas à SII.
								</blockquote>`,
          refs: [
            "World Gastroenterology Organisation Global Guidelines, Irritable Bowel Syndrome: a Global Perspective. 2015."
          ]
        },
        {
          type: "ThePage",
          name: "Causas",
          title: "As Causas de SII",
          slug: "causas",
          image: "sindrome/sindrome_causas.jpg",
          html: `
									<p>No passado, a SII foi associada a alterações na velocidade de passagem do conteúdo gástrico pelo intestino, a recetores nervosos demasiado sensíveis ao nível do intestino, a stress e a história familiar de SII.<sup>1</sup></p>

									<blockquote class="text-center">
										No entanto, atualmente, ainda não se conhece  a ou as causas exatas de SII.<sup>2</sup>
									</blockquote>

									<div class="icon-sindrome divider lime"></div>


					`,
          refs: [
            "What is IBS? – Irritable bowel syndrome (IBS). Disponível em: https://www.nhs.uk/conditions/irritable-bowel-syndrome-ibs/. Acedido a 2 de Fevereiro de 2021.",
            "World Gastroenterology Organisation Global Guidelines, Irritable Bowel Syndrome: a Global Perspective. 2015."
          ]
        },
        {
          type: "ThePage",
          name: "Sinais e Sintomas",
          title: "Sinais e Sintomas de SII",
          slug: "sinais-e-sintomas",
          image: "sindrome/sindrome_sinais_sintomas.jpg",
          html: `
									<p>Aquando da avaliação de um doente com SII, é importante considerar não só os sintomas de apresentação primários, mas também identificar os fatores desencadeantes e outros sintomas gastrointestinais e não gastrointestinais associados.<sup>1</sup></p>

									<h4 class="pink-hl">Assim, os principais sinais e sintomas de SII são:<sup>2</sup></h4>

									<blockquote class="list">
										<p>Dores abdominais ou cólicas que geralmente pioram depois de comer e melhoram depois de defecar.</p>

										<p>Distenção abdominal</p>

										<p>Diarreia</p>

										<p>Obstipação</p> 

										<p class="pink-hl">Pode haver dias em que os sintomas melhoram e dias em que pioram (crises). Sintomas intermitentes, crises que podem ser desencadeadas por determinados alimentos, bebidas ou stress e ansiedade.</p>
									</blockquote>

									<h4 class="pink-hl">A SII também pode levar a:<sup>2</sup></h4>

									<blockquote class="list">
										
										<p>Flatulência</p>

										<p>Prostração e falta de energia</p>

										<p>Náuseas</p>

										<p>Dores nas costas</p>

										<p>Alterações ao urinar <br /> <i>necessidade de urinar com frequência, vontade repentina de urinar ou sensação de não conseguir esvaziar totalmente a bexiga</i>.</p>

										Incontinência fecal
									</blockquote>


					`,
          refs: [
            "World Gastroenterology Organisation Global Guidelines, Irritable Bowel Syndrome: a Global Perspective. 2015.",
            "Symptoms – Irritable bowel syndrome (IBS). Disponível em: https://www.nhs.uk/conditions/irritable-bowel-syndrome-ibs/symptoms/. Acedido a 2 de fevereiro de 2021."
          ]
        },
        {
          type: "ThePage",
          name: "Classificação",
          title: "Classificação da SII",
          slug: "classificacao",
          image: "sindrome/sindrome_classificacao.jpg",
          html: `
								<table class="colored pink">
									<tr>
										<th>Subtipo</th>
										<th>Características</th>
									</tr>
									<tr>
										<td width="50%"><b>SII predomínio de obstipação</b></td>
										<td>&gt; 25% Bristol 1-2 e &lt; 25% Bristol 6-7</td>
									</tr>
									<tr>
										<td><b>SII predomínio de diarreia</b></td>
										<td>&gt; 25% Bristol 6-7 e &lt; 25% Bristol 1-2</td>
									</tr>
									<tr>
										<td><b>SII padrão misto</b></td>
										<td>&gt; 25% Bristol 1-2 e &gt; 24% Bristol 6-7</td>
									</tr>
									<tr>
										<td><b>SII não classificada</b></td>
										<td>Cumpre critérios de SII, mas a forma das fezes não é consistente</td>
									</tr>
								</table>`,
          refs: [
            "World Gastroenterology Organisation Global Guidelines, Irritable Bowel Syndrome: a Global Perspective. 2015."
          ]
        },
        {
          type: "ThePage",
          name: "Sinais de Alarme",
          title: 'Sinais de Alarme ou <span class="pink-hl">red flags</span>',
          slug: "sinais-de-alarme",
          image: "sindrome/sindrome_fatores_risco.jpg",
          html: ` <ul class="red-flags">
										<li>Idade > a 50 anos;</li>
										<li>Sexo masculino;</li>
										<li>Duração dos sintomas < 6 meses; </li>
										<li>Sintomas noturnos;</li>
										<li>História familiar de CCR ou DII; </li>
										<li>Anemia ou perdas hemáticas;</li>
										<li>Uso recente de antibióticos; </li>
										<li>Diarreia de grande volume;</li>
										<li>Aumento de marcadores inflamatórios; </li>
										<li>Perda ponderal não intencional, desnutrição;</li>
										<li>Dor abdominal contínua, progressiva, intensa;</li>
										<li>Alterações ao exame objetivo.</li>
									</ul>

					`,
          refs: [
            "World Gastroenterology Organisation Global Guidelines, Irritable Bowel Syndrome: a Global Perspective. 2015"
          ]
        }
      ]
    },
    {
      type: "TheDiagnostic",
      name: "Diagnóstico",
      slug: "diagnostico",
      icon: "diagnostico",
      image: "diagnostico/algoritmo_mobile_oculto_bacterias.svg",
      color: "pink",
      subPages: [
        {
          type: "ThePage",
          name: "Algoritmo completo",
          title: "Algoritmo completo",
          titleAlignment: "center",
          slug: "algoritmo-completo",
          html: `

          	<h4 class="pink-hl text-center">Dor Abdominal e Alteração do Trânsito Intestinal</h4>
          	<div class="text-center mt-2 mb-2">
          	 <img src="img/content/algo/algoritmo_mobile.svg" alt="Ilustração do algoritmo" width="90%" />
          	</div>

          `
        },
        {
          type: "ThePage",
          name: "Critérios ROMA",
          title: "Escala de Bristol & Critérios de ROMA",
          titleAlignment: "center",
          slug: "escala-bristol-criterios-roma",
          html: ``,
          tabs: [
            {
              title: "Critérios de ROMA IV<sup>2</sup>",
              slug: "roma",
              html: `
              	<p>Roma IV define a síndrome do intestino irritável (SII) como:  dor abdominal recorrente (pelo menos 1 dia/semana), associado com dois ou mais dos seguintes critérios:</p>

              	<ul class="checklist check green">
              		<li>Relacionados com a defecação</li>
              		<li>Associados com uma mudança na frequência dos hábitos intestinais</li>
              		<li>Associados com uma mudança na forma das fezes</li>
              	</ul>

              	<blockquote>O início dos sintomas devem ter ocorrido há pelo menos 6 meses e ter estado presente durante os últimos 3 meses.</blockquote>`
            },
            {
              title: "ESCALA DE BRISTOL<sup>1</sup>",
              slug: "bristol",
              html: `
								<table class="bristol">
									<tr>
										<td>
											<img src="img/content/escala-bristol/bristol_tipo_1.png" alt="Ilustração do tipo de cocó" width="100%" />
										</td>
										<td>TIPO 1</td>
										<td>Duras e separadas, como nozes  (difíceis de passar)</td>
									</tr>
									<tr>
										<td>
											<img src="img/content/escala-bristol/bristol_tipo_2.png" alt="Ilustração do tipo de cocó" width="100%" />
										</td>
										<td>TIPO 2</td>
										<td>Moldada como salsicha, fissuras profundas</td>
									</tr>
									<tr>
										<td>
											<img src="img/content/escala-bristol/bristol_tipo_3.png" alt="Ilustração do tipo de cocó" width="100%" />
										</td>
										<td>TIPO 3</td>
										<td>Formato tipo salsicha, mas com fissuras à superfície</td>
									</tr>
									<tr>
										<td>
											<img src="img/content/escala-bristol/bristol_tipo_4.png" alt="Ilustração do tipo de cocó" width="100%" />
										</td>
										<td>TIPO 4</td>
										<td>Como salsicha ou cobra, lisa e macia</td>
									</tr>
									<tr>
										<td>
											<img src="img/content/escala-bristol/bristol_tipo_5.png" alt="Ilustração do tipo de cocó" width="100%" />
										</td>
										<td>TIPO 5</td>
										<td>Borrões suaves, com bordas nítidas</td>
									</tr>
									<tr>
										<td>
											<img src="img/content/escala-bristol/bristol_tipo_6.png" alt="Ilustração do tipo de cocó" width="100%" />
										</td>
										<td>TIPO 6</td>
										<td>Fragmentos soltos, com bordas desfeitas</td>
									</tr>
									<tr>
										<td>
											<img src="img/content/escala-bristol/bristol_tipo_7.png" alt="Ilustração do tipo de cocó" width="100%" />
										</td>
										<td>TIPO 7</td>
										<td>Aquoso, sem fragmentos sólidos, (inteiramente líquido)</td>
									</tr>
								</table>
							`
            }
          ],
          refs: [
            "Lewis SJ, Heaton KW. Stool form scale as a useful guide to intestinal transit time. Scand J Gastroenterol. 1997;32(9):920-924.",
            "ROME IV criteria. Disponível em: https://theromefoundation.org/rome-iv/rome-iv-criteria/. Acedido a 2 de Fevereiro de 2021."
          ]
        },
        {
          type: "ThePage",
          name: "Meios Auxiliares de diagnóstico",
          title: "Meios Auxiliares de diagnóstico",
          titleAlignment: "center",
          slug: "meios-auxiliares-diagnostico",
          html: `
          			<table class="colored purple">
									<tr>
										<th width="50%"><span class="icon-people">Subtipo</span></th>
										<th><span class="icon-investigation">Características</span></th>
									</tr>
									<tr>
										<td colspan="2">
											<table role="presentation" border="0" width="100%">
												<tr>
													<td width="50%" rowspan="3" align="center"><b>Todos os doentes</b></td>
													<td>Hemograma + Proteína C Reactiva</td>
												</tr>
												<tr><td>+ Calprotectina fecal</td></tr>
												<tr class="green-hl">
													<td style="border-top-left-radius: 0; border-bottom-left-radius: 0">Exclusão de DII ou outra condição inflamatória</td>
												</tr>
											</table>
										</td>
									</tr>
									<tr>
										<td colspan="2">
											<table role="presentation" border="0" width="100%">
												<tr valign="top">
													<td width="50%" class="lime-hl"><b>SII predomínio de diarreia</b></td>
													<td>Exclusão de DII ou outra condição inflamatória</td>
												</tr>
												<tr valign="top">
													<td>Teste serológico, se positivo EDA</td>
													<td>Áreas com elevada prevalência de Doença Celíaca</td>
												</tr>
												<tr valign="top">
													<td>Inicio após viagem para países tropicais</td>
													<td>Cultura de Fezes </td>
												</tr>
											</table>
										</td>
									</tr>
									<tr>
										<td colspan="2">
											<table role="presentation" border="0" width="100%">
												<tr valign="top">
													<td width="50%" class="lime-hl">História Familiar de CCR</td>
 													<td rowspan="4" valign="middle" align="center">Colonoscopia com biópsia</td>
 												</tr>
 												<tr>
 													<td class="lime-hl">Perda de Peso involuntária</td>
 												</tr>
 												<tr>
 													<td class="lime-hl" style="border-top-right-radius: 0; border-bottom-right-radius: 0">Retorragia (excluindo origem anal)</td>
 												</tr>
 												<tr>
 													<td class="lime-hl">Massa abdominal</td>
 												</tr>
											</table>
										</td>
									</tr>									
									<tr>
										<td><b>50 anos</b></td>
										<td>Colonoscopia</td>
									</tr>
								</table>
								<br /> <br />
          `
        }
      ]
    },
    {
      type: "PageList",
      name: "Tratamento",
      slug: "tratamento",
      icon: "tratamento",
      color: "green",
      subPages: [
        /*{
          type: "ThePage",
          name: "Alflorex®",
          title: "Alflorex<sup>&reg;</sup>",
          slug: "alflorex",
          image: "tratamento/tratamento_alflorex.jpg",
          html: `
								<h4 class="green">ÚNICA SOLUÇÃO QUE APRESENTA OS BENEFÍCIOS COMPROVADOS DO <i>BIFIDOBACTERIUM LONGUM 35624<sup>®</sup></i></h4>

								<div class="gallery md-2cols align-center">

									<div>
										<img src="img/content/alflorex_packshot-2.png" alt="alflorex packshot" width="100%" />
										<br /><br />
									</div>

									<div>
										<div class="gallery sm-2cols align-center">
											<div>
												<img src="img/logos/logo_symbiosys.svg" alt="logo Symbiosys" width="100%" />
												<img src="img/logos/logo_alflorex.svg" alt="logo Symbiosys" width="100%" />
											</div>
	
											<div>
												<img src="img/logos/logo_WGO.svg" alt="logo Symbiosys" width="100%" />
											</div>
										</div>

										<p>
											<b>RECOMENDADO<br />  PELA WGO PARA SII<sup>1</sup></b> <br />
											<i>“Bifidobacterium Longum 35624<sup>®</sup> o probiótico com a melhor evidência, relativamente à eficácia na SII”</i><br />
											WGO, agosto 2015
										</p>
									</div>
								</div>

								

								<blockquote class="green-corners">
									<b>ALFLOREX<sup>®</sup> DEVE SER TOMADO DIARIAMENTE PELO MENOS DURANTE 4 SEMANAS</b>. NAS PRIMEIRAS SEMANAS PODEM NOTAR-SE ALTERAÇÕES NOS HÁBITOS INTESTINAIS, QUE IRÃO PASSAR COM A CONTINUAÇÃO DO TRATAMENTO.
								</blockquote>


								<p style="text-align: justify;"><small>Alflorex<sup>®</sup> é um dispositivo médico que contém a estirpe B. longum 35624<sup>®</sup>. Alflorex<sup>®</sup> destina-se ao tratamento da Síndrome do Intestino Irritável, incluindo sintomas de inchaço, gases, dor abdominal, diarreia e obstipação.<br /> INSTRUÇÕES DE UTILIZAÇÃO: Tomar 1 cápsula de Alflorex<sup>®</sup> por dia durante 1 mês. É importante que tome Alflorex<sup>®</sup> todos os dias e que termine o ciclo completo. Não exceda a dose diária recomendada. Alflorex<sup>®</sup> pode ser tomado com ou sem alimentos, a qualquer altura do dia. Enquanto tomar antibióticos, a dose diária de Alflorex<sup>®</sup> deverá ser tomada em separado da dose de antibióticos.<br /> EFEITOS SECUNDÁRIOS: Até à data, não foram observados efeitos secundários derivados da utilização de Alflorex<sup>®</sup>. Não existem interações conhecidas com suplementos alimentares ou outras medicações.<br /> CONTRAINDICAÇÕES: Não utilize o produto em caso de hipersensibilidade ou alergia individual a um ou mais componentes do produto.</small></p>


					`,
          refs: [
            "World Gastroenterology Organisation Global Guidelines, Irritable Bowel Syndrome: a Global Perspective. 2015."
          ]
        },*/
        {
          type: "ThePage",
          name: "Dieta",
          title: "Dieta",
          slug: "dieta",
          image: "tratamento/tratamento_dieta.jpg",
          html: `
								<!--<p>O que deve e o que não deve fazer para aliviar os sintomas da SII:</p>-->

								<div class="gallery md-2cols">
									<div>
										<h4 class="pill-green icon-circle-check">Deve</h4>
										<ul class="checklist check green">
											<li>Deve ter hábitos alimentares saudáveis</li>
											<li>Evitar grandes refeições</li>
											<li>Mastigar com calma</li>
											<li>Evitar consumo de alguns alimentos, caso a ingestão destes possa estar relacionado com agravamento de sintomas</li>
											<li>Experimentar probióticos indicados para a SII durante um mês</li>
										</ul>
									</div>
									
									<!--<div>
										<h4 class="pill-pink icon-circle-cross">Não Deve</h4>
										<ul class="checklist cross pink">
											<li>Atrasar ou saltar refeições;</li>
											<li>Comer demasiadamente depressa;</li>
											<li>Comer alimentos gordos, condimentados ou processados;</li>
											<li>Comer mais do que 3 porções de fruta fresca por dia (uma porção são 80 g);</li>
											<li>Beber mais do que 3 chávenas de chá ou café por dia;</li>
											<li>Beber muito álcool ou refrigerantes.</li>
										</ul>
									</div>-->
								</div>


								<!--<section class="gallery-icons md-list">
									<div>
										<img src="img/content/icons/tratamento_img_colicas.svg" alt="icone colicas" />
										<p><b>Para aliviar a distenção, as cólicas e os gases</b></p>
										<ul>
											<li>Tomar 1 colher de sopa de linhaça por dia;</li>
											<li>Evitar alimentos difíceis de digerir (couve, bróculos, couve flor, couve de Bruxelas, feijões, cebolas e fruta desidratada);</li>
											<li>Evitar produtos com sorbitol como adoçante;</li>
											<li>Consultar o seu médico.</li>
										</ul>
									</div>

									<div>
										<img src="img/content/icons/tratamento_img_diarreia.svg" alt="icone colicas" />
										<p><b>Para reduzir a diarreia</b></p>
										<ul>
											<li>Evitar alimentos ricos em fibra como o pão escuro e o arroz integral, nozes e sementes;</li>
											<li>Evitar produtos com sorbitol como adoçante;</li>
											<li>Consultar o seu médico.</li>
										</ul>
									</div>

									<div>
										<img src="img/content/icons/tratamento_img_obtipacao.svg" alt="icone colicas" />
										<p><b>Para reduzir a diarreia</b></p>
										<ul>
											<li>Beber muita água para suavizar as fezes;</li>
											<li>Aumentar a ingestão de fibra solúvel como aveia, leguminosas, cenouras, batatas descascadas e linhaça;</li>
											<li>Consultar o seu médico.</li>
										</ul>
									</div>
								</section>-->


					`,
          refs: [
            "Diet, lifestyle and medicines – Irritable bowel syndrome (IBS). Disponível em: https://www.nhs.uk/conditions/irritable-bowel-syndrome-ibs/diet-lifestyle-and-medicines/. Acedido a 2 de fevereiro de 2021."
          ]
        },
        {
          type: "ThePage",
          name: "Lifestyle",
          slug: "lifestyle",
          image: "tratamento/tratamento_lifestyle.jpg",
          html: `
								<p><b>Procurar formas de relaxar e de fazer exercício.</b></p>

								<p><b>O exercício regular</b> reduz os sintomas da SII porque <b>as pessoas fisicamente ativas têm movimentos gastrointestinais mais frequentes e rápidos</b> que as pessoas sedentárias. Foi demonstrado que doentes que praticam <b>20 a 60 minutos</b> de atividade física moderada a forte, três vezes por semana <b>reduz significativamente os sintomas da SII</b>.</p>

								<p>Uma revisão sistemática de seis ensaios clínicos aleatorizados demonstrou que os participantes com SII e que praticam yoga regularmente, viram os seus sintomas significativamente diminuídos e, simultaneamente, a sua ansiedade e qualidade de vida melhoraram.</p>


					`,
          refs: [
            "Lucak S, et al. Current and emergent pharmacologic treatments for irritable bowel syndrome with diarrhea: Evidence-based treatment in practice. Therapeutic Advances in Gastroenterology. 2017;10:253."
          ]
        } /*,
        {
          type: "ThePage",
          name: "Gestão do Stress",
          title: "Gestão do Stress",
          slug: "gestao-do-stress",
          image: "tratamento/tratamento_gestao_stress.jpg",
          html: `
								<p>Algumas das abordagens psicológicas para aliviar os sintomas da SII e melhorar a qualidade de vida dos doentes podem incluir:<sup>1</sup></p>

								<section class="gallery-icons md-list">
									<div>
										<img src="img/content/icons/tratamento_img_TCC.svg" alt="icone colicas" />
										<p><b>Terapia Cognitiva Comportamental (TCC)</b></p>
										
										<p>Em grupo ou individual. As técnicas comportamentais têm como objetivo modificar as condutas disfuncionais através de:</p>

										<ul>
											<li>técnicas de relaxamento;</li>
											<li>gestão das contingências (recompensação do comportamento saudável);</li>
											<li>treino assertivo.</li>
										</ul>
									</div>

									<div>
										<img src="img/content/icons/tratamento_img_hipnose.svg" alt="icone colicas" />
										<p><b>Hipnose</b></p>

										<p>Demonstrou-se que a hipnose é eficaz para a SII.<br />
										A hipnose envolve um relaxamento progressivo e sugestões de imagens e sensações focadas nos sintomas de cada doente. Verificam-se melhorias no bem-estar, na qualidade de vida, na dor abdominal, na obstipação e na diarreia. Ainda assim, a hipnose não deve ser entendida como uma cura, pois cerca de 25 % dos doentes não respondem a hipnose.<sup style="line-height:0">2</sup></p>
									</div>
								</section>

					`,

          refs: [
            "World Gastroenterology Organisation Global Guidelines, Irritable Bowel Syndrome: a Global Perspective. 2015.",
            "Hypnosis for IBS. Disponível em: https://www.aboutibs.org/complimentary-or-alternative-treatments/hypnosis-for-ibs.html. Consultado em 2 de fevereiro de 2021."
          ]
        }*/
      ]
    }
  ]
};
