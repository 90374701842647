<template>
  <div :class="['page', 'algo-page', page.color]">
    <header>
      <h2 class="title">{{ page.name }}</h2>
    </header>

    <!-- <div class="featured-image">
      <picture class="featured no-round">
        <img
          :src="require(`@/assets/img/${page.image}`)"
          :alt="page.name"
          v-if="page.image != ''"
        />
      </picture>
    </div> -->

    <form action="#" id="algo">
      <!-- SCREEN #Q1 : BEGIN -->
      <transition name="fade" mode="in-out">
        <div class="form-group" id="q-1" v-if="currentQuestion === 'q-1'">
          <h3 class="text-center mb-1">
            O doente apresenta algum destes
            <span class="pink-hl">Sinais de Alarme?</span>
          </h3>

          <fieldset class="gallery md-2cols">
            <!-- <legend class="h3">O doente apresenta alguma destas <span class="pink-hl">Red Flags?</span></legend> -->
            <div style="padding: 0">
              <input
                type="checkbox"
                id="rf-1"
                name="red-flags"
                value="Idade &gt; a 50 anos"
              />
              <label for="rf-1">Idade &gt; a 50 anos</label><br />

              <input
                type="checkbox"
                id="rf-2"
                name="red-flags"
                value="Sexo masculino"
              />
              <label for="rf-2">Sexo masculino</label><br />

              <input
                type="checkbox"
                id="rf-3"
                name="red-flags"
                value="Duração dos sintomas &lt; 6 meses"
              />
              <label for="rf-3"
                >Duração dos sintomas
                <span style="white-space: nowrap;">&lt; 6 meses</span></label
              ><br />

              <input
                type="checkbox"
                id="rf-4"
                name="red-flags"
                value="Sintomas noturnos"
              />
              <label for="rf-4">Sintomas noturnos</label><br />

              <input
                type="checkbox"
                id="rf-5"
                name="red-flags"
                value="História familiar de CCR ou DII"
              />
              <label for="rf-5">História familiar de CCR ou DII</label><br />

              <input
                type="checkbox"
                id="rf-6"
                name="red-flags"
                value="Anemia ou perdas hemáticas"
              />
              <label for="rf-6">Anemia ou perdas hemáticas</label><br />

              <input
                type="checkbox"
                id="rf-7"
                name="red-flags"
                value="Uso recente de antibióticos"
              />
              <label for="rf-7">Uso recente de antibióticos</label><br />
            </div>

            <div style="padding: 0">
              <input
                type="checkbox"
                id="rf-8"
                name="red-flags"
                value="Diarreia de grande volume"
              />
              <label for="rf-8">Diarreia de grande volume</label><br />

              <input
                type="checkbox"
                id="rf-9"
                name="red-flags"
                value="Aumento de marcadores inflamatórios"
              />
              <label for="rf-9">Aumento de marcadores inflamatórios</label
              ><br />

              <input
                type="checkbox"
                id="rf-10"
                name="red-flags"
                value="Perda ponderar não intencional, desnutrição"
              />
              <label for="rf-10"
                >Perda ponderar não intencional, desnutrição</label
              ><br />

              <input
                type="checkbox"
                id="rf-11"
                name="red-flags"
                value="Dor abdominal contínua, progressiva, intensa"
              />
              <label for="rf-11"
                >Dor abdominal contínua, progressiva, intensa</label
              ><br />

              <input
                type="checkbox"
                id="rf-12"
                name="red-flags"
                value="Alterações ao exame objetivo"
              />
              <label for="rf-12">Alterações ao exame objetivo</label>
            </div>
          </fieldset>
        </div>
      </transition>
      <!-- SCREEN #Q1 : END -->

      <!-- SCREEN #Q2 : BEGIN -->
      <transition name="fade" mode="in-out">
        <div class="form-group" id="q-2" v-if="currentQuestion === 'q-2'">
          <h3 class="text-center mb-1">
            No que diz respeito à
            <span class="pink-hl">investigação etiológica dirigida:</span>
          </h3>

          <fieldset>
            <!-- <legend class="h3">O doente apresenta alguma destas <span class="pink-hl">Red Flags?</span></legend> -->

            <div class="icon-magglass"></div>

            <div class="center-inputs">
              <input type="radio" id="ied-1" name="ied" value="normal" />
              <label for="ied-1">Normal</label><br />

              <input type="radio" id="ied-2" name="ied" value="anormal" />
              <label for="ied-2">anormal</label>
            </div>
          </fieldset>
        </div>
      </transition>
      <!-- SCREEN #Q2 : END -->

      <!-- SCREEN #Q3 : BEGIN -->
      <transition name="fade" mode="in-out">
        <div class="form-group" id="q-3" v-if="currentQuestion === 'q-3'">
          <h3 class="text-center mb-1">
            O doente cumpre os
            <span class="pink-hl">critérios de ROMA IV?</span>
          </h3>

          <fieldset>
            <!-- <legend class="h3">O doente apresenta alguma destas <span class="pink-hl">Red Flags?</span></legend> -->
            <ul class="checklist check green weight-normal">
              <li>≥ 1 dia/semana nos últimos 3 meses</li>
              <li>Dor abdominal recorrente 2 vezes ou mais</li>
              <li>
                A dor abdominal está associada a pelo menos 2 dos seguintes
                sintomas:<br />
                <span class="lime-hl">A.</span> Relacionada com a defecação<br />
                <span class="lime-hl">B.</span> Associada a alterações na
                frequência das fezes<br />
                <span class="lime-hl">C.</span> Associada a alterações na forma
                das fezes
              </li>
              <li class="lime-hl" style="list-style-type: ''">
                Critérios preenchidos nos últimos 3 meses e com início de
                sintomas pelo menos 6 meses antes do diagnóstico.
              </li>
            </ul>

            <div class="gallery sm-2cols">
              <div>
                <input type="radio" id="roma-1" name="roma" value="sim" />
                <label for="roma-1">Sim</label>
              </div>

              <div>
                <input type="radio" id="roma-2" name="roma" value="nao" />
                <label for="roma-2">Não</label>
              </div>
            </div>
          </fieldset>
        </div>
      </transition>
      <!-- SCREEN #Q3 : END -->

      <!-- SCREEN #Q4 : BEGIN -->
      <transition name="fade" mode="in-out">
        <div class="form-group" id="q-4" v-if="currentQuestion === 'q-4'">
          <h3 class="text-center mb-1">
            Os exames do doente estão normais?
          </h3>

          <fieldset>
            <!-- <legend class="h3">O doente apresenta alguma destas <span class="pink-hl">Red Flags?</span></legend> -->

            <h4>Hemograma</h4>
            <div class="gallery sm-2cols exame-opts">
              <div>
                <input
                  type="radio"
                  id="exame-1"
                  name="exames[1]"
                  value="normal"
                  checked
                />
                <label for="exame-1">Normal</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="exame-2"
                  name="exames[1]"
                  value="alterado"
                  class="red"
                />
                <label for="exame-2">Alterações</label>
              </div>
            </div>

            <h4>VS/PCR</h4>
            <div class="gallery sm-2cols exame-opts">
              <div>
                <input
                  type="radio"
                  id="exame-3"
                  name="exames[2]"
                  value="normal"
                  checked
                />
                <label for="exame-3">Normal</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="exame-4"
                  name="exames[2]"
                  value="alterado"
                  class="red"
                />
                <label for="exame-4">Alterações</label>
              </div>
            </div>

            <h4>IGA TTG</h4>
            <div class="gallery sm-2cols exame-opts">
              <div>
                <input
                  type="radio"
                  id="exame-5"
                  name="exames[3]"
                  value="normal"
                  checked
                />
                <label for="exame-5">Normal</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="exame-6"
                  name="exames[3]"
                  value="alterado"
                  class="red"
                />
                <label for="exame-6">Alterações</label>
              </div>
            </div>

            <h4>CALPROTECTINA FECAL</h4>
            <div class="gallery sm-2cols exame-opts">
              <div>
                <input
                  type="radio"
                  id="exame-7"
                  name="exames[4]"
                  value="normal"
                  checked
                />
                <label for="exame-7">Normal</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="exame-8"
                  name="exames[4]"
                  value="alterado"
                  class="red"
                />
                <label for="exame-8">Alterações</label>
              </div>
            </div>

            <h4>PSOF</h4>
            <div class="gallery sm-2cols exame-opts">
              <div>
                <input
                  type="radio"
                  id="exame-9"
                  name="exames[5]"
                  value="normal"
                  checked
                />
                <label for="exame-9">Normal</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="exame-10"
                  name="exames[5]"
                  value="alterado"
                  class="red"
                />
                <label for="exames10">Alterações</label>
              </div>
            </div>
          </fieldset>
        </div>
      </transition>
      <!-- SCREEN #Q4 : END -->
    </form>

    <div id="results" v-if="currentQuestion === 'results'">
      <div id="result-1" class="result" v-if="result === 'result-1'">
        <div class="icon-medicine main"></div>
        <h3>Proceder ao tratamento da causa</h3>
      </div>

      <div id="result-2" class="result" v-if="result === 'result-2'">
        <h3 style="margin-top: 0;">Trata-se de um caso de SII provável</h3>

        <p class="icon-drop">Pedir Hemograma e VS/PCR</p>
        <p class="icon-lifestyle">Modificação do estilo de vida</p>
      </div>

      <div id="result-3" class="result" v-if="result === 'result-3'">
        <h3 style="margin-top: 0;">Trata-se de um caso de SII provável</h3>

        <p class="icon-tratamento">Tratamento conservador</p>
        <p class="icon-diagnostico">Follow-up em 1-3 meses</p>
      </div>
    </div>

    <button @click.prevent="evaluateMasterAlgo">{{ btnLabel }}</button>
  </div>
</template>

<script>
export default {
  name: "PageList",
  props: {
    slug: {
      default: false
    }
  },
  data() {
    return {
      pages: this.$store.pages,
      currentQuestion: "q-1",
      result: "null",
      btnLabel: "Continuar",
      ans1: [],
      ans2: [],
      ans3: [],
      ans4: []
    };
  },
  components: {
    // HelloWorld
    // HomeSvg
  },
  computed: {
    page() {
      return this.pages.find(page => page.slug === this.$route.params.slug);
    }
  },
  mounted() {
    // console.log(this.$refs)
    this.$router.replace({ hash: "#" + this.currentQuestion });
    this.$ua.trackEvent("Algorithm", "Inicio", "Diagnostico");
  },
  methods: {
    trackAlgoResult(result) {
      //track result in GA
      this.$ua.trackEvent("Algorithm", "Final", "Diagnostico", result);
    },
    evaluateMasterAlgo() {
      var q1 = "red-flags",
        q2 = "ied",
        q3 = "roma",
        q4 = ["exames[1]", "exames[2]", "exames[3]", "exames[4]", "exames[5]"],
        r1 = "result-1",
        r2 = "result-2",
        r3 = "result-3",
        endLabel = "Terminar";

      var form = document.querySelector("form");

      if (this.btnLabel === endLabel) {
        this.$router.push({ name: "TheDiagnostic" });
        return;
      }

      /***************
       * Question 1
       ****************/
      if (this.currentQuestion === "q-1") {
        // console.log(form.elements[q1]);
        form.elements[q1].forEach((ans, i) => {
          this.ans1[i] = ans.checked;
        });

        //false = no symptoms | true = has symptoms
        if (!this.ans1.every(ans => ans === false)) {
          //has symptoms
          this.currentQuestion = "q-2";
          this.scrollToQuestion();
          return;
        } else {
          //has no symptoms
          this.currentQuestion = "q-3";
          this.scrollToQuestion();
          return;
        }
      }

      /***************
       * Question 2
       ****************/
      if (this.currentQuestion === "q-2") {
        form.elements[q2].forEach((ans, i) => {
          this.ans2[i] = ans.checked;
        });

        console.log(this.ans2);
        if (this.ans2.every(ans => ans === false)) {
          //has symptoms
          alert("Deve selecionar uma opção");
        } else {
          if (this.ans2[0] === true) {
            //no
            this.currentQuestion = "q-3";
            this.scrollToQuestion();
            return;
          } else {
            //yes
            this.currentQuestion = "results";
            this.result = r1;
            this.btnLabel = endLabel;
            this.scrollToQuestion();

            //track result in GA
            this.trackAlgoResult("result-1");
            return;
          }
        }
      }

      /***************
       * Question 3
       ****************/
      if (this.currentQuestion === "q-3") {
        form.elements[q3].forEach((ans, i) => {
          this.ans3[i] = ans.checked;
        });

        if (this.ans3.every(ans => ans === false)) {
          // this.currentQuestion = "q-3"
          alert("Deve selecionar uma opção");
        } else {
          if (this.ans2[0] === true) {
            // comes from q2
            if (this.ans3[0] === true) {
              //yes
              this.currentQuestion = "results";
              this.result = r2;
              this.btnLabel = endLabel;
              this.scrollToQuestion();

              //track result in GA
              this.trackAlgoResult("result-2");
              return;
            } else {
              //no
              this.currentQuestion = "results";
              this.result = r3;
              this.btnLabel = endLabel;
              this.scrollToQuestion();

              //track result in GA
              this.trackAlgoResult("result-3");
              return;
            }
          } else {
            //comes from q1
            if (this.ans3[0] === true) {
              //yes
              this.currentQuestion = "results";
              this.result = r2;
              this.btnLabel = endLabel;
              this.scrollToQuestion();

              //track result in GA
              this.trackAlgoResult("result-2");
              return;
            } else {
              //no
              this.currentQuestion = "q-4";
              this.scrollToQuestion();
              return;
            }
          }
        }
      }

      /***************
       * Question 4
       ****************/
      if (this.currentQuestion === "q-4") {
        // console.log(form.elements );
        q4.forEach((ans, i) => {
          this.ans4[i] = form.elements[ans].value;
        });

        console.log(this.ans4);
        if (this.ans4.includes("alterado")) {
          // alterations in examinations
          this.currentQuestion = "q-2";
          this.scrollToQuestion();
          return;
        } else {
          this.currentQuestion = "results";
          this.result = r3;
          this.btnLabel = endLabel;
          this.scrollToQuestion();

          //track result in GA
          this.trackAlgoResult("result-3");
          return;
        }
      }
    },
    scrollToQuestion() {
      setTimeout(() => {
        this.$router.push({
          hash: "#" + this.currentQuestion,
          params: {
            position: {
              offset: { y: 10 },
              behavior: "smooth"
            }
          }
        });
      }, 500);
    }
  },
  watch: {
    "$route.hash": function(val) {
      //second param of the watch functions is the oldVal
      // console.log("new: %s, old: %s", val, oldVal);
      this.currentQuestion = val.replace("#", "");
      if (val !== "#results") this.btnLabel = "Continuar";
    }
  }
};
</script>

<style lang="scss">
.algo-page {
  form {
    .form-group {
      h3 {
        padding: 0 1em;
      }
    }
    fieldset {
      background-color: var(--purple);
      color: var(--white-bg);
      border: none;
      border-radius: 2em;
      padding: 1.5em 1.5em 1.5em;

      legend {
        color: var(--gray-dark);
        text-align: center;
        display: block;
      }
      h4 {
        color: var(--white-bg);
        text-align: center;
        margin-top: 1.25em;
        font-size: 1em;
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      appearance: none;
      background-color: transparent;
      border: solid 2px var(--white-bg);
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0em 0 0.75em 0;
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label {
      display: block;
      margin: -2.5em 0 0 1.5em;
      padding-left: 0.75em;
      // text-indent: 1em;
    }

    input[type="checkbox"]:focus,
    input[type="radio"]:focus {
      outline: solid 1px var(--white-bg);
    }

    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
      position: relative;
      background-color: var(--lime);
      border-color: var(--lime);
    }

    input[type="checkbox"]:checked:before,
    input[type="radio"]:checked:before {
      @include icon-font-styles;
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: 0.9em;
      margin-top: 0.2em;
      margin-left: 0.1em;
      content: "\e90a";
      color: var(--purple);
    }

    input[type="radio"].red:checked:before {
      margin-top: 0.2em;
      margin-left: 0.2em;
      content: "\e90b";
      color: var(--purple);
    }

    input[type="radio"].red:checked {
      position: relative;
      background-color: var(--pink-light);
      border-color: var(--pink-light);
    }
  }

  #q-2 {
    .icon-magglass:before {
      color: var(--lime);
      font-size: 5em;
      display: block;
      margin: 0.25em auto;
      text-align: center;
    }
    fieldset {
      padding: 1em 1.5em 2em;
    }
    .center-inputs {
      display: block;
      width: 8em;
      margin: auto;
    }
  }

  .exame-opts {
    max-width: 300px;
    margin: 0 auto;
  }

  .result {
    background-color: var(--purple);
    color: var(--white-bg);
    border: none;
    border-radius: 2em;
    padding: 1.5em 1.5em 1.5em;
    margin: 0.5em 0;

    [class^="icon"].main:before {
      font-size: 5em;
      display: block;
      margin: 0.25em auto;
      text-align: center;
      color: var(--lime);
    }
    p[class^="icon"]:before {
      color: var(--lime);
      display: inline-block;
      font-size: 2em;
      margin-top: 0.5em;
      padding-right: 0.25em;
    }
    p {
      color: var(--white-bg);
      text-align: center;
    }
    h3 {
      text-align: center;
      color: var(--white-bg);
    }
  }
}
</style>
