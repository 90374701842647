<template>
  <nav>
    <ul class="bottom-menu" role="navigation">
      <!-- <li v-if="!goHomeActive" class="hide-xs">
        <a
          @click.prevent="
            $router.go(-1);
            $parent.$emit('setTransition', 'slide-back');
          "
          class="icon-arrow-left no-select"
          title="Retroceder"
        ></a>
      </li>
      <li v-else class="hide-xs">
        <a
          @click.prevent="$router.push({ name: 'Home' })"
          class="icon-home no-select"
          title="Voltar à Homepage"
        ></a>
      </li> -->
      <li v-for="item in mainMenu" :key="item.id">
        <router-link
          :to="{
            name: item.type,
            params: { slug: item.slug }
          }"
          :class="[
            'icon-' + item.icon,
            'theme-' + item.color,
            'menu-link',
            'no-select'
          ]"
          :title="item.title"
        >
          <span></span>
          <label>{{ item.name }}</label>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
// import axios from "axios";
// import {fetchMenus} from "@/assets/js/menu";

export default {
  name: "TheNavigation",
  props: {
    // menus: {
    //   required: true
    // },
    // goHomeActive: {
    //   required: false
    // }
  },
  data() {
    return {
      menu: this.$store.pages
    };
  },
  mounted() {},
  computed: {
    mainMenu() {
      return this.menu;
    },
    floatMenu() {
      return this.menus.floatMenu;
    }
    // maybe it would be a good idea to have an async computed property
    // that would await a promise to be resolved with the menu data an
    // return the filtered menu. this requires a plugin.
    // more info:
    // https://www.digitalocean.com/community/tutorials/vuejs-async-computed-properties
    // https://github.com/foxbenjaminfox/vue-async-computed
  },
  methods: {}
};
</script>
<!-- [class^="icon-"] -->
<style lang="scss" scoped>
.bottom-menu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  margin: 0;
  padding-bottom: env(safe-area-inset-bottom);

  li {
    display: block;
    list-style-type: none;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-left: -1.5em;
  }

  li:first-child {
    margin-left: 0em;
    label {
      text-indent: -0.75em;
    }
  }
  li:last-child label {
    text-indent: 0.75em;
  }

  a:before {
    display: block;
    font-size: 2em;
    padding-top: 0.25em;
    margin-bottom: 0.2em;
  }
  a {
    display: block;
    color: var(--white-bg);
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    font-size: 0.8em;

    span {
      position: relative;
      mix-blend-mode: multiply;
      // background-blend-mode: multiply;
      display: block;
      border: 2px solid var(--lime);
      background: var(--lime);
      border-radius: 2.5em;
      padding: 0.3em 0.5em;
      cursor: pointer;
      height: 2.25em;
    }
    label {
      cursor: pointer;
      position: absolute;
      bottom: 8%;
      width: 100%;
      display: block;
      z-index: 10;
    }

    &.theme-lime {
      &:before {
        color: var(--lime);
      }
      span {
        background: var(--lime);
        border-color: var(--lime);
      }
    }

    &.theme-pink {
      &:before {
        color: var(--pink);
      }
      span {
        background: var(--pink);
        border-color: var(--pink);
      }
    }

    &.theme-green {
      &:before {
        color: var(--green);
      }
      span {
        background: var(--green);
        border-color: var(--green);
      }
    }

    &.current-page,
    &.router-link-active {
      &.theme-lime {
        span {
          background: transparent;
        }
        label {
          color: var(--lime);
        }
      }
      &.theme-pink {
        span {
          background: transparent;
        }
        label {
          color: var(--pink);
        }
      }
      &.theme-green {
        span {
          background: transparent;
        }
        label {
          color: var(--green);
        }
      }
    }
  }

  //&.router-link-exact-active
}

@media only screen and (min-width: $sm) {
  .bottom-menu {
    a {
      span {
        // padding: 0.8em 0.5em;
        height: 2.25em;
      }
    }
  }
}

@media (min-width: $sm) and (max-height: 540px) and (orientation: landscape) {
}
</style>
