<template>
  <div class="home page">
    <!-- <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <!-- <HomeSvg /> -->

    <!-- <iframe width="375" height="812" src="https://xd.adobe.com/embed/a4191074-bf34-491e-b3de-1f6dbc8763ba-f14a/?fullscreen&hints=off" frameborder="0" allowfullscreen></iframe> -->
    <div class="hp-grid">
      <div>
        <h1 class="text-center">
          Guia Prático para o Diagnóstico da SII
        </h1>

        <button class="mb-2" @click="gotoAlgo">Iniciar Algoritmo</button>

        <img
          class="logo mb-1"
          src="@/assets/img/diagnostico/algoritmo_mobile_oculto.svg"
          alt="SII - Síndrome da Irritabilidade do Intestino"
          width="90%"
          style="margin: auto; display: block;"
        />
        <br />
        <br />
      </div>
      <!-- <div>
        <div class="mr-2 ml-2">
          <img
            src="img/logos/logo_symbiosys.svg"
            alt="logo Symbiosys"
            width="100%"
          />
          <img
            src="img/logos/logo_alflorex.svg"
            alt="logo Symbiosys"
            width="100%"
          />
        </div>

        <div class="text-center mb-4">
          <router-link
            :to="{
              name: 'ThePage',
              params: { slug: 'tratamento', subSlug: 'alflorex' }
            }"
            class="button purple  inline-b"
          >
            Saber Mais
          </router-link>
        </div>

        <div class="mr-2 ml-2 mb-2">
          <img
            src="img/content/alflorex_packshot-1.png"
            alt="logo Symbiosys"
            width="100%"
          />
        </div>
      </div> -->
      <!-- <div>
        <div class="fw-bgcolor">
          <img src="img/logos/logo_WGO.svg" alt="logo WGO" width="100%" />
          <p>
            <b>RECOMENDADO PELA WGO PARA SII<sup>1</sup></b><br />
            “Bifidobacterium Longum 35624<sup>®</sup> o probiótico com a melhor
            evidência, relativamente à eficácia na SII” WGO, agosto 2015
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
// import HomeSvg from "@/components/HomeSvg.svg?inline";

export default {
  name: "Home",
  components: {
    // HelloWorld
    // HomeSvg
  },
  methods: {
    gotoAlgo() {
      this.$router.push({
        name: "TheAlgorithm",
        params: { slug: "diagnostico" }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.home h1 {
  font-weight: 500;
  font-size: 2.2em;
  padding: 0 0.75em;
  max-width: 20ch;
  margin: 1em auto;
  text-transform: uppercase;
}

@media only screen and (min-width: $lg) {
  .home {
    // margin: 0 -250px;
    // margin: 0 -25% 0 -19%;
  }
  .hp-grid {
    display: flex;
    // flex:1 0 33%;
    flex-wrap: wrap;

    & > div:nth-child(1) {
      width: 80%;
      // margin-left: clamp(0px, 1%, 115px);
      margin: 0 auto;
    }

    & > div:nth-child(2) {
      // width: 25%;
      // margin-top: 6em;
      // background-color: #E7E9E4;
    }

    & > div:nth-child(3) {
      // max-width: 15%;
      // margin-top: 6em;
      // align-self: center;
      // background-color: #E7E9E4;
    }
  }
}
</style>
